import { render, staticRenderFns } from "./f-server-form-widgets.vue?vue&type=template&id=71a9a5bf&"
import script from "./f-server-form-widgets.vue?vue&type=script&lang=ts&"
export * from "./f-server-form-widgets.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/scalefish/releases/235/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71a9a5bf')) {
      api.createRecord('71a9a5bf', component.options)
    } else {
      api.reload('71a9a5bf', component.options)
    }
    module.hot.accept("./f-server-form-widgets.vue?vue&type=template&id=71a9a5bf&", function () {
      api.rerender('71a9a5bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/js/components/form/f-server-form-widgets.vue"
export default component.exports